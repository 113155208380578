<template>
  <v-footer color="primary" class="text-center" padless elevation="5">
    <v-row justify="center" no-gutters>
      <v-btn
        color="white"
        text
        :to="backButtonPath"
        target="blank"
        class="my-2"
      >
        <v-icon aria-label="go back to home page">mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn color="white" text :to="$route.path" class="my-2">
        {{ $route.name }}
      </v-btn>
    </v-row>
  </v-footer>
</template>

<script>
import { store } from "@/state/state";

export default {
  computed: {
    backButtonPath() {
      return store.invalidUser ? "/paywall" : "/";
    }
  }
};
</script>
